.categories {
	@include clearfix;
	margin-bottom: 20px;
	@media only screen and (max-width: 767px) {
		display: none;
	}
	figure {
		@include box-shadow(0 1px 2px rgba(0, 0, 0, 0.15));
		background: $white;
		height: 133px;
		float: left;
		margin-bottom: 20px;
		h2 {
			background: $color-brand;
			background: rgba($color-brand, 0.9);
			color: $color-contrast;
			display: inline-block;
			font-size: 20px;
			padding: 15px 20px;	
			position: absolute;
			z-index: 200;
			.ru_ru & {
				font-size: 16px;
			}
			.pl_pl & {
				font-family: $font-family;
			}
		}
		span {
			display: block;
			overflow: hidden;
			width: 276px;
			height: 125px;
			margin: 4px;
			position: relative;
			img { width: 100%; }
		}
	}
	.column {
		display: block;
		float: left;
		width: 288px;
	}
	.middle { margin: 0 28px 20px; }
	.bull-list {
		float: left;
		padding-left: 10px;
	}
}