.single-product {
	@media only screen and (max-width: 767px) {
		.wrap-content { background: $white url('images/backgrounds/wood-top-mobile.png') 0 0 no-repeat; }
		h1 { padding: 40px 15px 10px; }
		#right-aside { display: none; }
		.side-options { margin: 0; }
		.product-image {
			margin-bottom: 10px;
			img {
				float: left;
				width: 88px;
				margin: 5px 10px 10px 0;
				border: 1px solid #EBE4DB;
				padding: 10px;
			}
		}
	}
	.intro {
		display: block;
		float: left;
		width: 210px;
		padding-right: 20px;
		@media only screen and (max-width: 767px) {
			 float: left;
			 width: 170px;
			 padding-right: 0;
		}

		&__right {
			display: block;
			float: left;
			width: 210px;
			padding-right: 20px;

			img {
				max-width: 100%;
				height: auto;
			}
		}
	}
	.check-list {
		li {
			background: url('images/globals/icon-usp.png') 0 8px no-repeat;
			padding: 2px 0 4px 20px;
			line-height: 18px;
		}
	}
}

.show-tinting-link {
	background: url("images/globals/sprite.png") 0 -1273px no-repeat;
	padding: 10px 0 10px 45px;
	margin-left: -10px;
}

.green-cta,
%button-style {
	@include border-radius(3px);
	@include gradient(top, #aedc00, darken(#8ab700, 2%));
	color: $white;
	display: inline-block;
	font-size: 15px;
	font-weight: 700;
	margin-left: 0;
	margin: 5px 0 10px;
	padding: 10px 20px;
	text-shadow: 1px 1px 0 rgba(black, 0.3);
	text-decoration: none;
	&:hover, &:focus, &:visited, &:visited:hover {
		@include gradient(top, darken(#aedc00, 0%), darken(#8ab700, 7%));
		color: $white;
	}
}

#right-aside {
	display: block;
	width: 200px;
	float: left;
	background: #f5f1ed;
	margin-right: -20px;
	padding: 25px 20px 20px 20px;
	@media only screen and (max-width: $break-small) {
		margin-left: 15px;
		margin-right: 15px;
		width: 250px;
	}
	.shoplist-right {
		border-bottom: 1px solid #e1d6c7;
		padding-bottom: 30px;
		position: relative;
	}
	.storefinder-right {
		border-top: 1px solid $white;
		padding-top: 20px;
	}
	button {
		@extend %button-style;
		border: 0;
		margin-top: 10px;
	}
	input { width: 180px; }
}

#coverages {
	@media only screen and (max-width: $break-small) {
		 padding-top: 5px;
	}
	figure {
		border: 1px solid darken(#ebe4db, 5%);
		display: block;
		float: left;
		position: relative;
		width: 86px;
		height: 86px;
		margin-right: 10px;
		@media only screen and (max-width: $break-small) {
			border: 0;
			margin-bottom: 10px;
			margin-right: 15px;
			&:last-child { margin-right: 0; }
		}
		figcaption {
			@extend %sprite;
			display: block;
			background-position: 0 -306px;
			position: absolute;
			left: 0;
			top: 0;
			width: 76px;
			padding: 5px 0 15px 10px;
		}
	}
}

.download {
	display: block;
	clear: both;
	li {
		@extend %sprite;
		background-position: -484px -410px;
		padding: 2px 0 10px 25px;
		display: block;
		em { color: #ccc; }
	}
}

.side-options {
	display: block;
	width: 220px;
	float: left;
	padding-top: 20px;
	margin-bottom: 30px;
	margin-right: 20px;
	position: relative;
	@media only screen and (max-width: $break-small) {
		margin: 15px; width: 290px;
		h2 { font-size: 26px; }
	}
	figure {
		display: block;
		background: $white;
		border: 1px solid #ebe4db;
		margin-bottom: 10px;
		height: 229px;
		overflow: hidden;
		position: relative;
		span {
			margin: 5px 0 5px 5px;
			display: block;
			width: 208px;
			height: 229px;
			overflow: hidden;
			position: relative;
		}
		em {
			@extend %sprite;
			background-position: -423px -250px;
			text-indent: 100%;
			white-space: nowrap;
			overflow: hidden;
			display: block;
			width: 57px;
			height: 57px;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 30;
		}
		&:hover {
			@media only screen and (max-width: $break-small) {
				background: none;
			}
		}
		
		a {
			display: none;

			&:first-child {
				display: inline;
			}
		}
	}
}

.product-image em {
	@media only screen and (max-width: $break-small) {
		display: none;
	}
}

.group-thumbs {
	padding-bottom: 10px;
	@media only screen and (max-width: $break-small) {
		display: none;
	}
	figure {
		border: none;
		border-bottom: 4px solid #fff;
		width: 66px;
		height: 57px;
		display: block;
		overflow: hidden;
		float: left;
		&::hover {
			cursor: pointer;
			border-color: #a7d100;
		}
	}
	.current { border-color: #213203; }
	#middle-thumb { margin: 0 11px; }

}

.img-result {
	display: block;
	float: left;
	width: 210px;
	margin-right: 20px;
	position: relative;
	overflow: hidden;
	a em {
		background-position: -443px -207px;
		text-indent: 100%;
		white-space: nowrap;
		overflow: hidden;
		display: block;
		width: 57px;
		height: 57px;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 30;
	}
	figcaption {
		font-style: 400;
		border-bottom: 1px solid #ebe4db;
		padding: 5px 0 8px;
	}
	span {
		opacity: 0.5;
		position: absolute;
		width: 190px;
		height: 123px;
		left: 10px;
		top: 10px;
		display: block;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
	}
}

span.orange,
.orange span { background: #f60; }
span.brown,
.brown span { background: #534741; }
span.blue,
.blue span { background: #0076a3; }
span.red,
.red span { background: #f00; }
span.white,
.white span { background: #fff; }
span.green,
.green span { background: #197b30; }
span.purple,
.purple span { background: #92278f; }
span.pink,
.pink span { background: #f49ac1; }

.tabs {
	h2, h3 { font-size: 26px; }
}

#howto-element {
	@media only screen and (max-width: $break-small) {
		.steps { margin-bottom: 15px; }
		img { width: 290px; height: auto; }

		iframe {
			width: 100% !important;;
			max-width: 100% !important;;
			height: auto !important;
		}
	}
	h2 { margin: 1em 0 0 0; }

}

#productinfo-element p {
	@media only screen and (max-width: $break-small) {
		padding-bottom: 5px;
	}
}

a.info {
	@extend %sprite;
	display: block;
	width: 16px;
	height: 16px;
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
	background-position: -60px -540px;
	margin: -1px 0 0 10px;
	float: left;
}