.prod-overview .rating li {
	display: block;
	float: none;
	margin-bottom: 5px;
}

.refine {
	padding-top: 50px;
	@media only screen and (max-width: $break-small) {
		display: none;
	}
	.refine-title {
		font-weight: bold;
		color: #363636;
		text-decoration: none;
		display: inline-block;
		padding: 5px 0 0;
	}
	ul {
		padding: 10px 0 10px 0;
		margin-bottom: 10px;
		border-bottom: 1px solid #ebe4db;
		li {
			padding-bottom: 5px;
			input {
				margin-right: 10px;
				float: left;
				width: auto;
				/* IE8 */
				padding: 0;
				border: 0;
			}
			a {
				color: #363636;
				text-decoration: none;
				&:hover { text-decoration: underline; }
			}
			label:hover {
				cursor: pointer;
				text-decoration: underline;
			}
		}
	}
}

.purpose {
	padding-bottom: 15px;
	dt, dd { display: inline; }
	dd { font-weight: 700; }
}