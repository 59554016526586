.side-nav {
	display: block;
	width: 220px;
	float: left;
	padding-top: 15px;
	margin-bottom: 30px;
	margin-right: 20px;
	@media only screen and (max-width: $break-small) {
		padding: 40px 15px 0; width: 290px;
		figcaption { display: none; }
		.search &,
		.search-no-results &,
		.post-type-archive-faq &,
		.post-type-archive-howto &,
		.single-howto &,
		.tax-in-the-garden &,
		.tax-on-the-house &,
		.tax-in-the-house & {
			display: none;
		}
	}
	li a {
//		@extend %sprite;
//		background-position: 0 -1500px;
//		@media only screen and (max-width: $break-small) {
//			padding: 10px 15px;
//			background-position: -142px -708px;
//		}
	}
}

#left-nav {
	background: $white;
	margin-top: 2px;
	border: 1px solid #ebe4db;
	border-width: 1px 1px 0;
	li a {
		display: block;
		border: 1px solid #ebe4db;
		border-width: 0 0 1px 0;
		padding: 10px;
		text-decoration: none;
		&:hover {
			background-color: lighten(#f5efe7, 4%);
			color: #363636;
		}
	}
}

#left-nav li.current a,
#left-nav li.current-cat a,
#left-nav li.current a:visited,
#left-nav li.current-cat a:visited,
#left-nav li.current a:hover,
#left-nav li.current-cat a:visited,
#left-nav li.current a:visited:hover,
#left-nav li.current-cat a:visited:hover
#left-nav li.current a li.current a,
#left-nav li.current-cat a li.current a {
	border-width: 0 0 1px 10px;
	color: #363636;
}

#left-nav li.parent-left > a {
	@extend %sprite;
	background-position: -212px -708px;
	@media only screen and (max-width: $break-small) {
		background-position: -142px -708px;
	}
}

#left-nav li.parent-left-down > a {
	@extend %sprite;
	background-position: -192px -736px;
	@media only screen and (max-width: $break-small) {
		background-position: -122px -736px;
	}
}

#left-nav li.current {
	li a {
		font-weight: normal;
		color: #363636;
	}
	li.current-cat a { font-weight: 700; }
}

#left-nav li ul li a,
#left-nav li ul li a:visited,
#left-nav li ul li ul li a,
#left-nav li ul li ul li a:visited {
	font-weight: 400;
	padding: 10px 10px 10px 30px;
}

#left-nav li ul li.current a,
#left-nav li ul li ul li.current a {
	font-weight: 700;
}