.img-area {
	height: 150px;
	margin: 0 auto;
	position: relative;
	width: 980px;
	@media only screen and (max-width: $break-small) {
		display: none;
	}
	img {
		display: block;
		height: auto;
		overflow: hidden;
		width: 100%;
	}
	//&:before {
	//	@include transform(rotate(-1.4deg));
	//	background: #F7F3EF;
	//	bottom: -13px;
	//	content: "";
	//	display: block;
	//	height: 25px;
	//	position: absolute;
	//	overflow: hidden;
	//	width: 100%;
	//	@media only screen and (max-width: $break-small) {
	//		display: none;
	//	}
	//}
}

.prod-overview {
	@include clearfix;
	border-bottom: 1px solid #ebe4db;
	margin-bottom: 20px;
	@media only screen and (max-width: $break-small) {
		margin-bottom: 70px;
		article,
		article figure { width: 290px; height: auto; }
		article figure:hover { border-color: #fff; }
		article figure img { width: 100%; }
		article h2 { height: auto !important; }
		.prod-overview article,
		.prod-overview article.set-margin { margin: 0 0 20px 0; }
	}
	> div {
		@include clearfix;
	}
	article {
		display: block;
		float: left;
		width: 220px;
		margin: 0 0 30px;
		h2 {
			font-size: 26px;
			.pl_pl &,
			.hu_hu &,
			.cs_cz &,
			.sk_sk &,
			.ru_ru & { font-size: 22px; }
		}
		figure {
			background: $white;
			display: block;
			width: 220px;
			height: 163px !important;
			margin-bottom: 15px;
			.tax-area &,
			.page-template-page-area-php &,
			.page-template-page-inthehouse-php &,
			.page-template-page-onthehouse-php &,
			.page-template-page-inthegarden-php &,
			.page-template-page-help-advice-php & {
				height: 100px !important;
			}
			.post-type-archive-howto & {
				height: 110px !important;
			}
			a {
				height: auto;
				img {
					max-height: 100%;
					width: auto;
					.tax-area &,
					.page-template-page-area-php &,
					.page-template-page-inthehouse-php &,
					.page-template-page-onthehouse-php &,
					.page-template-page-inthegarden-php &,
					.page-template-page-help-advice-php & {
						width: 220px;
						height: 100px;
						vertical-align: top;
					}
				}
			}
		}
		.title {
			margin-bottom: 10px;
			display: block;
			line-height: 18px;
		}
		&.clear { clear: both; }
	}
	.set-margin {
		margin: 0 20px 20px 20px;
		@media only screen and (max-width: $break-small) {
			margin: 0 0 20px;
		}
	}
	.rating {
		padding: 0;
		width: 220px;
		margin: 0 0 5px 0;
		li {
			display: block;
			float: none;
			margin-bottom: 5px;
		}
	}
}

.prod-overview-products article figure {
	text-align: center;
	height: 120px;
	border-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	a {
		display: block;
		padding: 10px;
		width: 200px;

	}
	&:hover { border: 0; }
}