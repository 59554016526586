.related article {
    width: 210px;
    float: left;
    margin-right: 15px;

    @media only screen and (max-width: $break-small) {
        margin-bottom: 1em;
        width: 290px;
    }

    .rating {
        width: 205px;
        @media only screen and (max-width: $break-small) {
            width: 270px;
            height: auto;
        }

        li {
            display: block;
            float: none;
            margin-bottom: 5px;
        }
    }

    figure {
        background: #fff;
        display: block;
        min-height: 145px;
        padding: 10px;
        text-align: center;
        overflow: hidden;
        margin-bottom: 15px;

        @media only screen and (max-width: $break-small) {
            width: 270px;
            height: auto;
        }
    }

    .ru_ru & {
        width: 205px;
    }
}

@media only screen and (max-width: $break-small) {
    #related {
        width: 275px;
        margin-bottom: 20px;
        padding: 15px 15px 15px 0;
    }
    #related figure {
        width: 120px;
        height: 146px;
        float: left;
        margin: 10px 15px 0;
        padding: 0;
    }
    #related figure + figure {
        margin: 10px 0 0;
    }
    #related img {
        width: 75%;
    }
    #related figcaption {
        padding: 0 15px;
    }
}