#productfinder {
	@media only screen and (max-width: $break-small) {
		background: #DB6619 url(images/backgrounds/texture-mobile.png) repeat-y;
		position: relative;
		padding: 15px;
		width: 290px;
	}
}

.finder-selects {
	background: $color-brand;
	background: rgba($color-brand, 0.9);
	display: block;
	float: left;
	width: 240px;
	margin-left: 20px;
	padding: 30px;
	position: relative;
	@media only screen and (max-width: $break-small) {
		background:rgba(116,51,18,0.87);
		float: none;
		padding: 25px;
		margin: 0;
		position: relative;
	}
	&:before {
		@extend %sprite;
		background-position: 0 -250px;
		content: "";
		display: block;
		height: 10px;
		left: 0;
		position: absolute;
		width: 100%;
		bottom: -10px;
		z-index: 100;
		@media only screen and (max-width: $break-small) {
			display: none;
		}
	}
	h2 {
		color: #edebda;
		font-size: 32px;
		.ru_ru & { font-size: 24px; }
		.lv_lv & { font-size: 26px; }
		@media only screen and (max-width: $break-small) {
			color: $white;
			padding: 0;
			margin-bottom: 5px;
			font-size: 28px;
			text-shadow: 0 1px 4px #52250f;
		}
	}
	p {
		color: $white;
		padding: 2px 0 13px;
		@media only screen and (max-width: $break-small) {
			color: #fff;
			text-shadow: 0 1px 4px #52250f;
			margin-bottom: 10px;
			padding: 0 0 8px 0;
			width: auto;
		}
	}
	form {
		position: relative;
		z-index: 100;
		@media only screen and (max-width: $break-small) {
			width: 266px;
			margin-left: -3px;
		}
	}
	button {
		@extend %sprite;
		@include border-radius(4px);
		@include box-shadow((inset 0 1px 0 rgba(white, 0.3), 0 2px 2px rgba(black, 0.2)));
		background-color: orange;
		background-position: 0 0;
		border: 0;
		color: $white;
		font-family: $font-family;
		font-size: 18px;
		font-weight: 700;
		margin-left: 26px;
		margin-top: 10px;
		padding: 11px 0;
		text-shadow: 1px 1px 2px rgba(black, 0.3);
		width: 186px;
		.ru_ru & {
		  margin-left: 11px;
    	   width: 220px;	
		}
		@media only screen and (max-width: $break-small) {
			@include gradient(top, #aedc00, darken(#8ab700, 2%));
			font-size: 16px;
			margin-left: 0;
			margin-top: 10px;
		}
		&:hover, &:focus {
			background-color: #FFA500;
			background-position: 0 -50px;
			@media only screen and (max-width: $break-small) {
				background-position: 0 0;
				@include gradient(top, darken(#aedc00, 0%), darken(#8ab700, 5%));
			}	
		}
	}
	#browse {
		display: none;
		@media only screen and (max-width: $break-small) {
			color: #FFF;
			display: block;
			margin-top: 10px;
			padding-bottom: 8px;
			&:hover, &:focus {
				text-decoration: none;
			}	
		}
	}
}

#select-list li {
	position: relative;
	a {
		@extend %sprite;
		display: block;
		width: 226px;
		height: 31px;
		padding: 14px 0 0 14px;
		margin-bottom: 9px;
		background-position: 0 -150px;
		text-decoration: none;
		color: #363636;
		&:hover, &:focus { background-position: 0 -100px; }
	}
	.is-active { font-weight: 700; }
	ul {
		@include border-radius (3px);
		@include box-shadow (0 1px 3px rgba(50, 50, 50, 0.25));
		display: none;
		position: absolute;
		left: 4px;
		top: 38px;
		background: $white;
		z-index: 2000;
		width: 230px;
		border: 1px solid #c2c2c2;
		li a {
			width: auto;
			height: auto;
			margin: 0;
			background: #ebebeb;
			padding: 8px 10px;
			border-bottom: 1px solid #c2c2c2;
			z-index: 2000;
			@media only screen and (max-width: $break-small) {
				padding: 13px 0 13px 13px;
			}
			&:hover { background: darken(#ebebeb, 5%); }
		}
		li:last-child a { border: 0; }
	}
}