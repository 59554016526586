.tab-nav {
	display: block;
	clear: both;
	background: #eee8e0;
	margin-top: 46px;
	border: 1px solid #d8d0c5;
	border-width: 1px 0;
	margin-bottom: 20px;
	li {
		a {
			color: #363636;
			text-decoration: none;
			padding: 10px 15px;
			display: block;
			float: left;
		}
		.is-selected {
			border: 1px solid #d8d0c5;
			border-width: 1px 1px 0;
			background: #F7F3EF;
			color: #363636;
			font-weight: bold;
			margin: -5px 0 -4px 0;
			padding: 13px 13px 12px;
			span {
				top: 12px;
			}
		}
	}
	.reviews {
		a {
			position: relative;
			padding-right: 54px;
			span {
				@extend %sprite;
				@include border-radius(8px);
				@include box-shadow(inset 0 1px 0 rgba($black, 0.2));
				display: block;
				width: 29px;
				height: 14px;
				padding-top: 4px;
				background: darken(#f5f1ed, 12%);
				position: absolute;
				right: 15px;
				top: 8px;
				text-align: center;
				font-size: 11px;
			}
			.et_ee &{
				padding-left: 13px;
			}
		}
		.is-selected {
			padding: 13px 50px 12px 15px;
			span { top: 12px; }
		}
	}
}

@media only screen and (max-width: 767px) {
	.tabs .tab-li section {
		display: block !important;
	}
}

//.mobile-tab-nav li:last-child { display: none; }