header {
	height: 132px;
	@media only screen and (max-width: $break-small) {
		background: #1e3407;
		height: 60px;
		width: 100%;
	}
	form {
		display: block;
		width: 980px;
		margin: 0 auto;
		clear: both;
		height: 84px;
		fieldset {
			float: right;
			position: relative;
			width: 222px;
			margin-top: 23px;
		}
		input {
			@include box-shadow(0 1px 2px rgba(0,0,0,.15));
			background: $white;
			background: rgba(white, 0.85);
			border: 0;
			color: #666e5c;
			border-radius: 5px;
			font-size: 13px;
			padding: 11px 10px;
			width: 220px;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 5;
		}
		button {
			@extend %sprite;
			position: absolute;
			right: 5px;
			top: 8px;
			z-index: 6;
			width: 20px;
			height: 21px;
			border: none;
			background-position: -88px -658px;
			background-color: transparent;
			overflow: hidden;
			text-indent: -1234em;
			white-space: nowrap;
			overflow: hidden;
			cursor: pointer;
		}
	}
}