.location_search {
	.no-linebreak input {
		width: auto;
		margin-right: 3px;
		font-weight: 400;
	}
	label { cursor: pointer; }
}

@media only screen and (max-width: 767px) {
	#map_search {
		td { display: block; padding-top: 0; }
		.location_search_empty_cell { display: none; }
		.location_search_submit_cell { padding-top: 7px; }
		select { font-size: 16px; }
		.submit { float: none; }
		.result {
			padding: 15px;
			h3 { padding-left: 0; line-height: 20px; }
		}
		.result_address { padding: 0; }
		.result_link a { text-align: left; }
		.page-template-page-storelocator-php & {
			overflow: auto;
			padding: 15px !important;
			margin-bottom: 15px;
		}
	}	
	#simplemap { margin-bottom: 0 !important; }
}

.pl_pl table.location_search {
    width: 100%;
    td {
        vertical-align: middle !important;
        width: auto;
    }
}