nav {
	width: 980px;
	display: block;
	margin: 0 auto;
	background: $color-brand;
	height: 66px;
	@media only screen and (max-width: $break-small) {
		@include box-shadow(inset -4px 0 14px -7px $black);
		background: #152704;
		width: 260px;
	}
	ul {
		@media only screen and (max-width: $break-small) {
			background: #112003;
			padding-top: 60px;
		}
		li {
			position: relative;
			display: inline-block;
			float: left;
			background: url('images/backgrounds/sep-nav.png') 100% 16px no-repeat;
			@media only screen and (max-width: $break-small) {
				background: none;
				clear: both;
				display: block;
				width: 240px;
			}
			&:last-child { background: none; }
		}
		a,
		a:visited {
			position: relative;
			font-family: $font-web;
			font-size: 18px;
			color: #CED1CC;
			text-decoration: none;
			display: block;
			float: left;
			padding: 24px 30px 20px;
			border-bottom: 4px solid $color-brand;
			.ru_ru &,
			.hu_hu &,
			.cs_cz &,
			.sk_sk &,
			.ee_ee &,
			.et_ee &,
			.lt_lt & {
				padding: 27px 18px 21px;
				font-size: 16px;
			}
			.lv_lv & {
				padding: 24px 25px 20px;
			}
			.pl_pl & {
				padding: 24px 20px 20px;
			}
			.ru_ru &,
			.hu_hu &,
			.lv_lv &,
			.lt_lt &,
			.et_ee &,
			.et_ee &,
			.cs_cz &,
			.sk_sk &,
			.pl_pl & {
				font-family: $font-family;
			}

			.ru_ru & {
				font-size: 14px;
			}
		}
		a:hover, 
		a:visited:hover,
		a.hover {
			color: $white;
		}	
		a span {
			@extend %sprite;
			display: block;
			background-position: 100% -863px;
			padding-right: 20px;
			@media only screen and (max-width: $break-small) {
				background-image: transparent;
			}
		}
		.current a {
			background: #0e1a03;
			border-bottom: 4px solid $color-contrast;
			color: $color-contrast;
			@media only screen and (max-width: $break-small) {
				border-bottom: 1px;
			}
		}
	}
	.shoppinglist {
		@media only screen and (max-width: $break-small) {
			display: none;
		}
	}
}

.wp-pagenavi {
	color: #f7f3ef;
	margin-top: 20px;
	display: flex;

	span,
	a {
		color: #142504;
		border: 1px solid #142504;
		background: #f7f3ef;
		height: 24px;
		width: 24px;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 0 1 auto;
		margin-right: 10px;
		text-decoration: none;

		&:last-child {
			margin-right: 0;
		}
	}

	.current,
	a:hover {
		color: #f7f3ef;
		background: #142504;
	}

	.first,
	.last,
	.pages {
		width: auto;
		padding: 0 4px;
	}
}