.news .post {
	@include clearfix;
	border-bottom: 1px solid #EBE4DB;
	padding: 20px 0 0;
	h2 {
		font-size: 26px;
		color: #006F9F;
		padding-bottom: 5px;
		padding-top: 5px;
		a {
			text-decoration: none;
			&:hover { text-decoration: underline; }
		}
	}
	img {
		float: left;
		margin: 0 20px 20px 0;
	}
	p {
		padding-bottom: 20px;
		a {
			display: block;
			padding-top: 5px;
		}
	}
}

.news-detail {
	h1 { margin-bottom: 8px; }
	figure { margin-bottom: 1em; }
	.size-full { width: 100%; height: auto; }

	.video-container {
	    position: relative;
	    padding-bottom: 56.25%;
	    padding-top: 30px; height: 0; overflow: hidden;
	    margin-bottom: 1em;

	    iframe,
	    object,
	    embed {
	        position: absolute;
	        top: 0;
	        left: 0;
	        width: 100%;
	        height: 100%;
	    }
	}
}