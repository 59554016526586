#selector-element h3 { clear: both; }
#substrates{
	margin: 0;
	width: 230px;
	li{
    	width: 105px;
    	height: 105px;
    	float: left;
    	margin: 0 10px 10px 0;
    	overflow: hidden;
        a {
        	width: 105px;
        	display: block;
        	height: 0;
        	padding-top: 105px;
        	overflow: hidden;
        	background: url('images/colour_selector/sprite.jpg') no-repeat 0 0;
        }
    }
    &#planter { margin-left: 58px; }
}

#substrates li#arbour a { background-position: -105px 0; }
#substrates li#fence a { background-position: -210px 0; }
#substrates li#furniture a { background-position: -315px 0; }
#substrates li#planter a { background-position: -420px 0; }