%sprite {
	background-image: url('images/globals/sprite.png');
	background-repeat: no-repeat;
}

.center { text-align: center; }
.alignleft { float: left; }
.alignright { float: right; }

.visible-phone {
	display: none;
	@media only screen and (max-width: 767px) {
		display: block;
	}
}
.visible-desktop {
	display: block;
	@media only screen and (max-width: 767px) {
		display: none;
	}
}

.clear-link { display: block; }

.hidden, .gform_hidden  { display: none; }
.hidden-force { display: none !important; }

figure {
	&.with-border {
		img {
			border: 1px solid #000;
		}
	}
}