//---- Settings
$font-family: Arial, sans-serif;
$font-web: 'swis721_ltcn_btlight', $font-family;

$break-small: 767px;

$color-brand: #142504;
$color-contrast: #F1AA00;

$white: #fff;
$black: #000;