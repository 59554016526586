#compare {
	background: url(images/backgrounds/bg-compare.png) left top repeat-x;
	padding: 30px 0 20px 0;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 400;
	@media only screen and (max-width: $break-small) {
		display: none;
	}
}

.prod-compare {
	display: block;
	float: left;
	width: 270px;
	margin-right: 20px;
	position: relative;
	figure {
		display: block;
		overflow: hidden;
		width: 76px;
		height: 88px;
		float: left;
	}
	.rating {
		width: auto;
		position: absolute;
		left: 96px;
		top: 0;
		li {
			display: block;
			float: none;
			font-weight: 700;
		}
		li.delete {
			margin-top: 20px;
			a {
				font-weight: 400;
				color: #f00;
				display: inline-block;
				padding: 2px 0;
			}
		}
	}
}

.add-extra {
	display: block;
	float: left;
	background: #eee8e0;
	padding: 33px 20px;
	border: 1px solid #d8d0c5;
	margin-right: 20px;
	width: 220px;
	text-align: center;
}

.cta-compare {
	@extend %sprite;
	@include border-radius(4px);
	@include box-shadow((inset 0 1px 0 rgba(white, 0.3), 0 2px 2px rgba(black, 0.2)));
	margin-top: 20px;
	display: block;
	float: left;
	width: 103px;
	height: 24px;
	padding-top: 10px;
	background-position: 0 -5px;
	color: $white;
	font-weight: 700;
	text-shadow: 1px 1px 2px rgba(black, 0.3);
	text-align: center;
	text-decoration: none;
	&:hover, &:focus, &:visited, &:visited:hover {
		background-color: orange;
		background-position: 0 -55px;
		color: $white;
	}
}

