.crumbs-options {
	border-bottom: 1px solid #ebe4db;
	padding: 10px 0 15px 0;
	color: #959595;
	position: relative;
	@media only screen and (max-width: $break-small) {
		display: none;
	}
	.options {
		float: right;
		li a {
			@extend %sprite;
			display: block;
			float: left;
			margin-left: 35px;
			background-position: -461px -929px;
			padding: 1px 0 1px 25px;
		}
		li.print a { background-position: -462px -899px; }
	}
}

.breadcrumbs li a {
	@extend %sprite;
	color: #959595;
	display: block;
	float: left;
	background-position: 100% -685px;
	padding-right: 10px;
	margin-right: 8px;
}
