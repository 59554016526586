@media only screen and (max-width: $break-small) {
	.mob-search-form {
		background: #DB6619 url(images/backgrounds/texture-mobile.png) repeat-y;
		width: 290px;
		padding:15px 15px 10px;
		z-index: 400;
		display: none;
		body.home & { padding-bottom: 0; }
		div {
			padding: 15px;
			background:rgba(116,51,18,0.87);
		}
		input {
			@include box-shadow(inset 0 0 5px #888);
			background: $white;
			font-size: 12px;
			padding: 6px 5px;
			border: none;
			width: 160px;
			border-radius: 3px;
			margin-right: 15px;
		}
		button {
			@include border-radius(3px);
			height: 27px;
			width: 73px;
			background: #54220a;
			border: 0;
			color: $white;
			&:hover, &:focus { background: #411805; }
		}
	}
	.tabs {
		width: 290px;
		padding-bottom: 20px;
		> div { padding: 0; }
		span { padding: 0 15px; display: block; }
		.mobile-tab-nav {
			width: 290px;
			> li {
				margin-bottom: 5px;
				clear: both;
				> a {
					background: #f6f4ee url('img/globals/mobile-sprite.png') right -447px;
					padding-left: 14px;
					font: 400 24px $font-web;
					color: #203403;
					display: block;
					border: 1px solid #ece8de;
					line-height: 38px;
					text-decoration: none;
					.hu_hu &,
					.ru_ru &,
					.lv_lv &,
					.cs_cz &,
					.sk_sk &,
					.lt_lt &,
					.ee_ee &,
					.ua_ua & {
						font-family: $font-family;
						font-size: 30px;
					}
				}
				> a.selected {
					background: $white url('img/globals/mobile-sprite.png') right -478px no-repeat;
					height: 37px;
					border-top: 2px solid #5C9D16;
				}
				a > span { display: none; }
			}
		}
		section { padding: 15px 0; }
		.tab-li > div > h2 { padding: 0 15px; }
		.tab-li h2 { font-size: 20px; }
		.note {
			background: #fcebb5 url('img/globals/mobile-sprite.png') 15px -377px no-repeat;
			padding: 15px 0 15px 53px;
		}
		.step .note { 
			margin: 10px 0 10px 15px;
			padding: 15px 15px 15px 50px;
			width: 155px;
		}
	}
}

.outer-scroll nav {
	ul li a {
		font-family: $font-family;
		font-size: 14px;
		padding: 0 20px;
		text-shadow: 0 1px 1px #000;
		border-top: 1px solid #263616;
		border-bottom: 1px solid #263616;
		display: block;
		line-height: 2.4rem;
		height: auto;
		width: 205px;
		color: $white;
		margin-bottom: -1px;
		z-index: 100;
		a.hover,
		&:hover {
			@include box-shadow(inset -4px 0 14px -7px #000);
			background: #2f510a;
			border-top: 1px solid #3f601b;
			border-bottom: 1px solid #0c1701;
			z-index: 200;
		}
		span {
			background-image: none;
		}
	}
	.current a { color: $color-contrast; }
	.lang { display: none; }
	#service-nav { background: none; }
}

#mobile-footer {
	background: #523d22;
	height: 40px;
	.wrapper {
		margin: 0 auto;
		width: 290px;
		height: 40px;
		text-align: center;
	}
	ul {
		display: inline-block;
		padding-top: 11px;
		float: none;
		li { 
			float: left;
			display: block;
			line-height: 19px;
			a {
				color: #cbc5bd;
				text-decoration: none;
				padding: 0 25px;
				border-right: 1px dotted #bab1a7;
				background: none;
				&:active { color: $white; }
			}
		}
		li:first-child a { padding-left: 0; }
		.last a {
			border: 0;
			padding-right: 0;
		}
	}
}