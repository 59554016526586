.fly-out {
	@include box-shadow(0px 5px 3px rgba(50, 50, 50, 0.29));
	position: absolute;
	left: 0;
	top: 66px;
	width: 400px;
	height: auto;
	background: #2a450d;
	clear: both;
	z-index: 1000;
	display: none;
	padding: 10px 20px 20px;
	@media only screen and (max-width: $break-small) {
		display: none !important;
	}
	ul { width: 180px; }
	li {
		display: block;
		float: none;
		background: none;
		a,
		a:visited {
			font-family: $font-family;
			font-size: 13px;
			display: block;
			float: none;
			padding: 5px 0 !important;
			border-bottom: 1px solid #2a450d;
			background: #2a450d;
		}
		a:hover, 
		a:visited:hover {
			background-image: none;
			border-color: #94a286;
		}
	}
	figure {
		@include box-shadow((0 10px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset));
		margin-top: 5px;
		float: right;
		width: 200px;
		height: 110px;
		background: $white;
		span {
			display: block;
			overflow: hidden;
			width: 190px;
			height: 100px;
			margin: 5px 0 0 5px;
		}
	}
}
