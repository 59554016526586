input,
textarea {
	border-radius: 5px;
	background: $white;
	border: 1px solid #E0D4C5;
	font-size: 13px;
	padding: 8px 10px;
	width: 200px;
}
textarea { width: 400px; }

.frm-contact {
	@media only screen and (max-width: $break-small) {
		input,
		textarea {
			margin-top: 5px;
			width: 268px;
			font-size: 13px;
		}
	}
	h2 { padding: 0; }
	div {
		display: block;
		overflow: hidden;
		height: 1%;
		padding-bottom: 10px;
	}
	li {
		clear: both;
	}
	label {
		display: block;
		float: left;
		font-weight: 700;
		width: 160px;
		cursor: pointer;
		padding: 10px 0;
	}
	fieldset {
		padding-bottom: 20px;
		display: block;
		overflow: hidden;
		height: 1%;
	}
}