.light-brown button,
.dark-slider button,
.finder-content button,
.frm-contact .gform_button,
.location_search_submit_cell .submit {
    @extend %sprite;
    @include border-radius(3px);
    @include box-shadow((inset 0 1px 0 rgba(white, 0.3), 0 2px 2px rgba(black, 0.2)));
    background-position: 0 -2px;
    border: 1px solid #aa6515;
    width: 166px;
    height: 40px;
    cursor: pointer;
    float: right;
    color: $white;
    font-weight: 700;
    font-size: 15px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    .ru_ru & {
        width: 210px;
    }
}

.light-brown .location_search_widget td,
.dark-slider .location_search_widget td {
    border: 0;
}

.light-brown button:hover,
.dark-slider button:hover,
.finder-content button:hover,
.frm-contact .gform_button:hover,
.location_search_submit_cell .submit:hover {
    @extend %sprite;
    background-position: 0 -52px;
    background-color: #FFA500;
}

.finder-content button {
    float: none;
}

.light-brown {
    @include clearfix;
    background: #eee8e0;
    border-top: 1px solid #d8d0c5;
    box-shadow: inset 0 1px 0 white;
    box-shadow: inset 0 1px 0 rgba(white, 0.5);
    width: 920px;
    padding: 20px 30px;
    display: block;
    margin: 0 auto;
    color: #787571;
    clear: both;
    h3 {
        font-size: 24px;
    }
}

.light-brown button,
.dark-slider button {
    float: none;
    margin-top: 15px;
}

.light-brown header {
    display: block;
    float: left;
    width: 240px;
    position: relative;
    height: 150px
}

.light-brown header span {
    @extend %sprite;
    background-position: -130px -320px;
    display: block;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    width: 147px;
    height: 55px;
    position: absolute;
    left: 40px;
    top: 40px;
}

.contact-list li {
    padding-bottom: 5px;
    &.set-space {
        padding-top: 15px;
    }
    a {
        text-decoration: underline;
        color: #787571;
    }
}

.ask-experts {
    position: relative;
    h3 {
        padding-left: 78px;
    }
    p {
        padding: 0 20px 0 78px;
        span {
            @extend %sprite;
            display: block;
            margin-top: 17px;
            background-position: -406px 5px;
            padding-left: 10px;
        }
    }
    figure {
        position: absolute;
        top: 0;
        left: -33px;
    }
}

.crumbs-options form,
.wishmail-form form {
    background: #1e3407;
    position: absolute;
    right: -20px;
    top: 0;
    padding: 20px;
    z-index: 30;
}

.wishmail-form form {
    top: 157px;
    left: 20px;
    right: auto;
}

.crumbs-options form input,
.wishmail-form form input {
    display: block;
    margin-bottom: 10px;
    border-radius: 5px;
    background: $white;
    border: 1px solid #cebca4;
    width: 188px;
    padding: 8px 5px;
}

.crumbs-options form input {
    border: 1px solid $white;
}

.crumbs-options form a,
.wishmail-form form a {
    color: $white;
}

.crumbs-options form button,
.wishmail-form form button {
    @include border-radius(4px);
    @include box-shadow((inset 0 1px 0 rgba(255, 255, 255, 0.3), 0 2px 2px rgba(0, 0, 0, 0.2)));
    line-height: 24px;
    @extend %sprite;
    width: 103px;
    height: 34px;
    margin-right: 10px;
    border: none;
    background-color: #1e3407;
    background-position: 0 -3px;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    text-shadow: 1px 1px 2px rgba($black, 0.3);
}

.lb-container {
    position: relative;
    .overlay-img {
        opacity: 0.5;
        position: absolute;
        width: 480px;
        height: 480px;
        left: 20px;
        top: 20px;
        display: block;
    }
}

.location_search button,
.crumbs-options form button:hover,
.wishmail-form form button:hover {
    background-position: 0 -54px;
}

.finder-content select {
    display: block;
    width: 250px;
    margin-bottom: 10px;
    float: left;
}

select.select-info {
    width: 225px;
}

.no-nav {
    padding-top: 0;
}

.img-200x150 {
    display: inline-block;
    width: 200px;
    height: 150px;
    float: right;
    border: 1px solid #ebe4db;
    margin: 10px 0 0 20px;
    -webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset
}

.img-200x150 span {
    display: block;
    width: 190px;
    height: 140px;
    overflow: hidden;
    margin: 5px;
}

.frm-contact em,
.gform_description,
div.gfield_description {
    margin: 0;
    color: #898989;
    display: block;
    padding: 0 0 20px 0;
    font-style: italic
}

.frm-contact div em,
div.gfield_description {
    display: block;
    padding: 0 0 20px 160px
}

.frm-contact .radio-list input {
    width: auto;
    border: none;
    float: left;
    margin-right: 15px;
    margin-left: 1px;
    padding: 0
}

.frm-contact .gfield_radio label {
    font-weight: normal;
    float: none;
    width: auto;
    display: inline;
}

.frm-contact .gfield_radio input {
    margin-right: 5px;
    margin-left: 2px;
    width: auto;
}

.frm-contact .gfield_radio li {
    padding: 2px 0;
}

.frm-contact .radio-list li {
    padding-bottom: 5px;
    font-weight: bold;
    display: block;
    height: 15px;
}

.frm-contact .radio-list li label {
    font-weight: normal;
    width: auto;
}

.frm-contact div .radio-list {
    display: block;
    float: left;
    width: 300px;
}

.frm-contact .gform_button {
    float: none;
    margin-left: 160px;
}

.content-wide {
    padding: 20px 0 40px 0;
}

/* @end */

.img-210 {
    display: block;
    float: left;
    width: 210px;
    margin-right: 20px;
    position: relative;
    overflow: hidden;
}

.select-item {
    padding-bottom: 20px
}

.select-item li a {
    display: block;
    float: left;
    background: #eee8e0;
    width: 50px;
    height: 50px;
    margin-right: 10px
}

/* @end */

/* Form popups */
.plain-page {
    background: none;
}

#c7box .frm-contact div, #c7box .frm-contact .gfield {
    clear: both;
    border-bottom: 1px solid #eee;
    padding: 12px 0;
    overflow: hidden
}

#c7box .frm-contact .ginput_container, #c7box .frm-contact .gfield_description {
    margin: 0;
    padding: 0;
    clear: none;
    border: 0
}

#c7box .frm-contact .gform_heading, #c7box .frm-contact .gform_body, #c7box .frm-contact .gform_footer {
    border: 0
}

#c7box .left_label .gfield_description {
    margin: 5px 0 0 160px;
    color: #666
}

.gfield_description ul {
    list-style: disc;
    margin-left: 20px
}

.gfield_description ul li {
    padding-top: 4px
}

#c7box li.pros input, #c7box li.cons input {
    margin-bottom: 3px
}

#c7box .frm-contact li.pros, #c7box .frm-contact li.cons {
    clear: none;
    width: 360px;
    float: left;
    margin: 0 20px 0 0;
    padding: 10px 0 0 0;
    border: 0;
    background: none;
}

#c7box .frm-contact li.pros label, #c7box .frm-contact li.cons label {
    float: none;
    display: block
}

#c7box .frm-contact li.pros input, #c7box .frm-contact li.cons input {
    margin-right: 5px;
}

#c7box .frm-contact em {
    clear: both;
    color: #666;
    display: block
}

#c7box .frm-contact .clicktorate em {
    width: auto;
    clear: none;
    float: left;
    padding: 2px 0 0 16px
}

#c7box .frm-contact .reviewinfo {
    clear: none;
    padding-bottom: 10px
}

#c7box .frm-contact .reviewinfo ul {
    list-style: disc;
    padding-left: 20px
}

#c7box .frm-contact .reviewinfo ul li {
    padding-top: 4px
}

.frm-contact .gfield_radio {
    margin: 1px
}

.frm-contact .gfield_radio li {
    float: left;
    margin: 0 15px 1em 0
}

.frm-contact .gfield_radio input {
    float: none;
    width: auto;
    height: auto;
    border: 0;
    margin-right: 5px
}

.frm-contact .gfield_radio label {
    float: none;
    font-weight: normal
}

#c7box .frm-contact .radios {
    float: left
}

#c7box .frm-contact .radios li .radio {
    width: auto;
    margin: 5px 10px 0 0;
    float: left
}

#c7box .frm-contact .radios li label {
    width: 300px;
    font-weight: normal
}

#c7box .frm-contact .radios li {
    padding-bottom: 4px;
    overflow: hidden
}

#c7box .frm-contact button {
    padding-bottom: 4px;
    margin: 20px 20px 20px 10px;
    line-height: 0
}

#c7box .frm-left {
    float: left;
    width: 600px
}

#c7boxabsolute .frm-right {
    width: 280px;
    position: absolute;
    top: 20px;
    right: 30px;
    z-index: 999
}

#c7box .pros-cons em {
    padding-left: 0
}

#c7box .pros-cons ul {
    width: 300px;
    float: left;
    margin: 0 20px 0 0;
    padding: 10px 0 0 0
}

#c7box .pros-cons ul li {
    padding-bottom: 6px;
    overflow: hidden
}

#c7box .pros-cons ul li a {
    display: block;
    clear: both
}

/*	Media queries - Mobile  ------------------------------------------	*/
@media only screen and (max-width: 767px) {

    /* Adjustments navigation */
    body > nav, body > .outer-scroll > nav {
        left: -240px;
        top: 0;
        bottom: 0;
        height: 100%;
        min-height: 100%;
        overflow: hidden;
        position: absolute;
        z-index: 9999;
        width: 240px;
        padding: 0;
    }

    /* Product summary */
    #summary {
        position: relative;
        width: 260px;
        margin-top: 45px;
        padding: 0 15px;
    }
    #summary p {
        width: 160px;
        float: right;
        padding: 0;
    }
    #summary figure.productimg {
        border: 1px solid #ece8de;
        width: 80px;
        height: 80px;
        float: left;
        margin-top: 3px;
        position: relative;
    }
    #summary figure.productimg img {
        width: 100%;
        height: 100%;
    }

    /* Product features */
    #procs {
        border: 0;
        padding: 0;
        width: 290px;
        margin-top: 10px;
    }
    #procs li {
        background: none;
        float: none;
        text-align: right;
        width: 260px;
        line-height: 37px;
        height: 37px;
        border: 1px dotted #d7d8d8;
        border-width: 0 0 1px 0;
        margin: 0;
        padding: 0 15px;
        color: #373a3b;
        font-weight: bold;
    }
    #procs li em {
        color: inherit;
        display: inline-block;
        float: left;
        font-weight: normal;
        padding: 0;
    }

    /* Features */
    dl.features {
        width: 260px;
        padding: 0 15px;
    }
    dl.features dd {
        width: 135px;
        float: left;
        clear: none;
    }
    dl.features dd span {
        padding: 0;
    }
    dl.features dt {
        width: 125px;
        float: left;
    }

    /* Form */
    .frm-contact_wrapper h3 {
        margin-top: 15px;
    }
    .gform_body,
    .gform_body label {
        width: 290px;
    }
    .frm-contact .ginput_container {
        height: auto;
        overflow: visible;
    }
    .frm-contact .gform_button {
        margin-left: 0;
    }
    .frm-contact div em,
    div.gfield_description {
        padding-left: 0;
    }

    /* Light-brown */
    .light-brown {
        width: 290px;
        padding: 15px;
    }
    .light-brown header {
        background: none;
        display: block;
        float: none;
        height: auto;
        margin-bottom: 1em;
        width: auto;
    }
    .light-brown article {
        margin-right: 0;
        width: 290px;
    }
    .light-brown article:first-child {
        //margin-right: 10px;
    }

    .finder-content {
        display: none;
    }

    .home .light-brown {
        display: none;
    }
}