.tt-wrapper {
	padding: 20px 0 0;
	width: 435px;
	height: 39px;
	margin: 0;
	display: block;
	clear: both;
	@media only screen and (max-width: $break-small) {
		padding: 20px 0 0;
	}
	li {
		float: left;
		a {
			display: block;
			width: 39px;
			height: 39px;
			margin: 0 2px;
			outline: none;
			background: transparent url('images/globals/icons.png') 0 0 no-repeat;
			text-indent: -999em;
			position: relative;
			span {
				@include box-shadow(1px 1px 2px rgba(0,0,0,0.3));
				@include transition(all 0.3s ease-in-out);
				width: 100px;
				height: auto;
				padding: 10px;
				left: 50%;
				margin-left: -64px;
				font-size: 12px;
				color: #333;
				text-align: center;
				border: 4px solid $white;
				background: $white;
				text-indent: 0px;
				border-radius: 2px;
				position: absolute;
				pointer-events: none;
				bottom: 100px;
				-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
				filter: alpha(opacity=0);
				opacity: 0;
				&:before,
				&:after {
					content: '';
					position: absolute;
					bottom: -15px;
					left: 50%;
					margin-left: -9px;
					width: 0;
					height: 0;
					border-left: 10px solid transparent;
					border-right: 10px solid transparent;
					border-top: 10px solid rgba(0,0,0,0.1);
				}
				&:after {
					bottom: -14px;
					margin-left: -10px;
					border-top: 10px solid $white;
				}
			}
			&:hover,
			&:focus {
				opacity: 0.8;
			}
			&:hover span,
			&:focus span {
				bottom: 45px;
				-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
				filter: alpha(opacity=100);
				opacity: 1.0;
			}
		}
		.tt-green { background-position: 0 0; }
		.tt-orange { background-position: 0 -40px; }
		.tt-blue { background-position: 0 -80px; }
		.tt-brown { background-position: 0 -120px; }
	}
}