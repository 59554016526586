table {
	margin-bottom: 20px;
	td {
		vertical-align: top;
		border-bottom: 1px solid #ebe4db;
		width: 230px;
		padding: 7px 0;
		font-weight: 700;
		em {
			display: block;
			float: left;
		}
	}
	.title {
		background: url('images/backgrounds/bull-blue.png') 0 13px no-repeat;
		font-weight: normal;
		padding: 7px 0 7px 10px;
		width: 220px;
	}
}

#compare-table {
	margin-bottom: 0;
	figure {
		margin: 0 10px 20px 0;
		border: 1px solid #d8d0c5;
	}
	th {
		width: 242px;
		text-align: left;
		background: #fff;
		span {
			display: block;
			overflow: hidden;
			width: 230px;
			height: 128px;
			text-align: center;
			margin-left: 5px;
			border: 1px solid #e3e3e3;
		}
		.no-product { background: #fafafa; }
	}
	th,
	td {
		border-bottom: 1px solid #e3e3e3;
		vertical-align: top;
	}
	td {
		padding: 11px 5px;
		font-weight: 400;
	}
	.first { width: 244px; }
	.td-title {
		@extend %sprite;
		font-weight: bold;
		padding: 11px 10px;
		background: left -450px;
	}
	.striped { background: #f0eeee; }
}

.cookie-types-table {
	tr {
		td:first-of-type {
			white-space: nowrap;
		}
	}

	td {
		padding: 10px;
	}
}