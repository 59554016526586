#service {
	position: relative;
	float: right;
	@media only screen and (max-width: $break-small) {
		float:none;
		margin-left: 197px;
	}
	li {
		display: inline;
		margin: 0;
		padding: 0;
		float: left;
		position: relative;
		@media only screen and (max-width: $break-small) {
			display: none;
		}
		&:hover > ul { display: block; }
		a {
			padding: 16px 16px 16px 15px;
			color: #ced1cc;
			background-image: url('images/backgrounds/sep-y.png');
			background-position: 100% 4px;
			background-repeat: no-repeat;
			text-decoration: none;
			display: inline-block;
			.ru_ru & { background-image: none; }
			&:hover, &:focus {
				color: $white;
				border-color: #627152;
			}
		}
		.last { border-bottom: 0; }
	}
	.current a {
		color: $white;
		border-color: $white;
	}
	.shoppinglist {
		@media only screen and (max-width: $break-small) {
			display: none;
		}
		a {
			padding-right: 45px;
			position: relative;
			span {
				@extend %sprite;
				background-position: -60px -660px;
				width: 24px;
				height: 18px;
				color: #132402;
				padding: 5px 0 2px;
				display: block;
				position: absolute;
				right: 15px;
				top: 11px;
				text-align: center;
				font-weight: 700;
			}
			&:hover span { color: $black; }
		}
	}	
	.lang {
		@media only screen and (max-width: $break-small) {
			display: block;
		}
		&:hover {
			border-color: $white;
			@media only screen and (max-width: $break-small) {
				background-color: #32570A;
			}
			a {
				color: #1e3407;
				background-color: $white; 
				@media only screen and (max-width: $break-small) {
					color: $white;
					&:hover {
						background-color: #32570A;
						color: $white;
					}
				}
			}
			> ul li {
				@media only screen and (max-width: $break-small) {
					display: block;
				}
			}
		}
		a {
			background-image: url('images/globals/flags.png');
			background-repeat: no-repeat;
			padding: 17px 65px 17px 45px;
			position: relative;
			display: block;
			@media only screen and (max-width: $break-small) {
				color: $white;
				font-weight: 700;
				padding: 9px 3px 9px 23px;
				text-transform: uppercase;
			}
			&:hover {
				background-color: $white;
				border-color: $white;
				color: #1e3407;
				@media only screen and (max-width: $break-small) {
					padding: 9px 3px 9px 23px;
				}
			}
			em {
				@extend %sprite;
				position: absolute;
				right: 10px;
				top: 21px;
				display: block;
				width: 13px;
				height: 8px;
				text-indent: 100%;
				white-space: nowrap;
				overflow: hidden;
				background-position: -120px -480px;
				@media only screen and (max-width: $break-small) {
					display: none;
				}
			}
		}
	}
	.lang-gb a {
		background-position: 0 18px;
		@media only screen and (max-width: $break-small) {
			background-position: -17px 10px;
		}
	}
	.lang-nl a {
		background-position: 0 -24px;
		@media only screen and (max-width: $break-small) {
			background-position: -17px -31px;
		}
	}
	.lang-de a { background-position: 0 -66px; }
	.lang-pl a { background-position: 0 -108px; }
	.lang-ru a { background-position: 0 -150px !important; }
	ul {
		@include box-shadow (0px 5px 3px rgba(50, 50, 50, 0.29));
		top: 48px;
		left: 0px;
		list-style: none;
		margin: 0;
		padding: 0;
		position: absolute;
		z-index: 9999;
		width: 156px;
		background: $white;
		display: none;
		overflow: hidden;
		@media only screen and (max-width: $break-small) {
			@include box-shadow(none);
			background: transparent;
			width: 47px;
			top: 32px;
		}
		li {
			clear: both;
			width: 100%;
			@media only screen and (max-width: $break-small) {
				@include border-radius(2px);
				background-color: #355C0B;
			}
			a {
				background: none;
				padding: 7px 15px;
				color: #1e3407;
				border: 0;
				float: left;
				clear: both;
				width: 150px;
				@media only screen and (max-width: $break-small) {
					background: darken(#355C0B, 2%);
					width: 100%;
				}
				&:hover {
					text-decoration: underline;
					@media only screen and (max-width: $break-small) {
						background-color: #78856a;
						text-decoration: none;
					}
				}
			}
		}
		li ul {
			top: 0;
			left: 181px;
		}
	}
}