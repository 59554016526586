.colors {
	border-bottom: 1px solid #ebe4db;
	padding-bottom: 20px;
	margin-bottom: 20px;
	@media only screen and (max-width: $break-small) {
		padding: 0 5px 20px;
	}
	li {
		@extend %sprite;
		margin-left: -10px;
		margin-left: 0px\9;
		display: inline-block;
		float: left;
		width: 52px;
		height: 52px;
		background-position: -332px -350px;
		background: url(images/backgrounds/bg-color-wood-IE.png) 0 0 no-repeat\9;
		position: relative;
		span {
			@include opacity(0.5);
			display: block;
			text-indent: 100%;
			white-space: nowrap;
			overflow: hidden;
			width: 36px;
			height: 36px;
			position: absolute;
			left: 8px;
			top: 8px;
			border-radius: 35px;

			&.no-opacity {
				@include opacity(1);
			}
		}
		&.multicolor {
			background-position: 0px -1109px;
		}
	}
}

.side-options figure {
	border: 1px solid #EBE4DB;
	display: block;
	height: 229px;
	margin-bottom: 20px;
	overflow: hidden;
}
.single-product .column-460 { 
	position: relative;
}
.clr-popup,
.clr-popup-multicolor {
	position: absolute;
	top: 0;
	left: 237px;
	height: 200px;
	background: #F7F3EF url(images/backgrounds/dotted.png) left 100px repeat-x;
	padding: 40px 0 0 1px;
	width: 460px;
	z-index: 9;
	display: none;
    @media only screen and (max-width: $break-small) {
        position: static;
        height: auto;
		width: auto;
		padding: {
			right: 10px;
			bottom: 10px;
			left: 10px;
		}
		margin-bottom: 20px;
    }
	h2 {
		display: block;
		height: 40px;
		background: url(images/globals/sprite.png) 0 -1161px no-repeat;
		background: url(images/backgrounds/bg-color-wood-IE_small.png) 0 0 no-repeat\9;
		position: relative;
		padding: 0 0 0 50px;
		line-height: 40px;
		span {
			@include opacity(0.6);
			display: block;
			position: absolute;
			left: 10px;
			top: 10px;
			height: 20px;
			width: 20px;
			text-indent: -9999em;
			font-size: 0em;
			border-radius: 26px;
		}
	}
	ul {
		margin-top: 30px;
		li { 
			float: left;
			margin-right: 20px;
			width: 100px;
			em {
				font-weight: bold;
				font-style: normal;
				color: #333;
				display: block;
				padding: 0 0 8px 0;
			}
			figure {
				width: 100px;
				height: 70px;
				background: url(images/globals/sprite.png) -100px -1201px no-repeat;
				position: relative;
				margin: 0;
				span {
					background: url(images/globals/sprite.png) 0 -1201px no-repeat;
					height: 10px;
					position: absolute;
					top: 0;
					left: 0;
					font-size: 0em;
					text-indent: -9999em;
					width: 100px;
					z-index: 2;
					margin: 0;
				}
				em {
					width: 100px;
					height: 70px;
					text-indent: -9999em;
					font-size: 0em;
					z-index: 1;
					position: absolute;
					left: 0;
					top: 0;
					padding-bottom: 0;
					background-image: none;
				}
			}
		}
		.layers-0 figure em { display: none; }
		.layers-1 figure em { @include opacity(0.4); }
		.layers-2 figure em { @include opacity(0.6); }
	}
}

.clr-popup-multicolor {
	h2 { background-position: 0 -1271px; }
	p { margin-top: 40px; }
}