.dark-slider {
	background: $color-brand;
	display: block;
	width: 920px;
	margin: 0 auto;
	padding: 20px 30px 30px;
	position: relative;
	@media only screen and (max-width: 767px) {
		display: none;
	}
	h3,
	p { color: $white }
	h4 {
		font-size: 20px;
		color: $white;
		text-align: center;
		.pl_pl & {
			font-family: $font-family;
		}
	}
	.article,
	article a {
		color: #d5d2ce;
	}
}

#prev-store,
#next-store {
	@extend %sprite;
	background-position: -370px -100px;
	display: block;
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	width: 55px;
	height: 55px;
	position: absolute;
	left: -35px;
	top: 72px;
}
#next-store {
	background-position: -370px -155px;
	left: 900px;
}
#prev-store:hover { background-position: -425px -100px; }
#next-store:hover { background-position: -425px -155px; }

#stores {
	display: block;
	overflow: hidden;
	width: 810px;
	height: 85px;
	margin: 10px 0 0 60px;
	position: relative;
	ul {
		width: 4000px;
		position: absolute;
		left: 0;
		top: 0;
		li {
			display: inline;
			a {
				@extend %sprite;
				display: block;
				float: left;
				width: 146px;
				height: 85px;
				margin-right: 20px;
				background-position: -334px 0;
			}
		}
	}
}

.slide-stores {
	position: relative;
	display: block;
	clear: both
}
#content .slide-stores {
	border-top: 1px solid #2c3b1e;
	margin-top: 20px;
	padding-top: 20px
}

.last-col figure {
	display: block;
	margin-bottom: 10px;
}

footer {
	padding-bottom: 95px;
	@media only screen and (max-width: 767px) {
		margin: 0;
		width: 100%;
		background: none;
		padding: 0;
		z-index: 200;
		position: fixed;
		bottom: 0;
		.footer,
		#claim { display: none; }
		#to-top {
			bottom: 0;
			color: #678599;
			left: 0;
			right: 0;
			text-align: center;
			height: 42px;
			line-height: 42px;
			a { 
				color: #678599;
				text-decoration: none;
			}
		}
	}
	section {
		background: $color-brand;
		margin: 0 auto;
		width: 980px;
		@media only screen and (max-width: 767px) {
			display: none;
		}
		div {
			border-top: 1px solid #2c3b1e;
			margin: 0 25px;
			padding: 20px 0;
		}
	}
	ul {
		float: right;
		padding-top: 3px;
		li {
			a, 
			a:visited {
				@extend %sprite;
				background-position: 0 -224px;
				color: #b6b0a9;
				display: block;
				float: left;
				padding: 2px 10px 2px 20px;
				text-decoration: none;
			}
			a:hover { color: #fff; }
		}
		li:first-child a { background: none; }
		li.last-item a { padding: 2px 0 2px 20px; }
		.ru_ru & {
			padding-bottom: 8px;
			li a,
			li a:visited { font-size: 12px; }
		}
	}
}