.review {
	border-bottom: 1px solid #d8d0c5;
	padding-bottom: 20px;
	margin-bottom: 20px;
	@media only screen and (max-width: $break-small) {
		padding: 20px 15px !important;
		width: 260px;
		h2 {
    		float: none;
    		clear: both;
    		margin-bottom: 2px;
}
		.rating { float: none; padding: 0; margin: 0 0 10px 0; }
		em { padding-bottom: 10px; }
		p { padding: 0; }
	}
}

.rate-review,
.answer {
	padding: 10px;
	background: #eee8e0;
	border: 1px solid #d8d0c5;
	margin: 20px 0 10px 0;
	display: block;
	clear: both;
}

.rate-review {
	font-size: 13px;
	em,
	ul { float: left; }
	li { margin-left: 10px; }
	span {
		font-style: italic;
		float: right;
	}
}


.pros,
.cons {
	width: 195px;
	margin-right: 20px;
	float: left;
	margin-bottom: 20px;
	@media only screen and (max-width: $break-small) {
		width: 260px;
		float: none;
	}
	li {
		@extend %sprite;
		line-height: 140%;
		padding: 0 0 10px 20px;
		background-position: -406px -537px;
	}
	.title {
		background-image: none;
		font-weight: 700;
		padding: 0 0 10px;
	}
}
.cons li { background-position: -377px -567px; }