.steps {
	padding-bottom: 20px;
	@media only screen and (max-width: $break-small) {
		padding-bottom: 0;
		p { display: none; }
	}
	li {
		line-height: 160%;
		border-bottom: 1px solid #d8d0c5;
		position: relative;
		min-height: 36px;
		display: block;
		padding: 10px 0 10px 45px;
		em {
			font-weight: bold;
			text-align: center;
			font-style: italic;
			display: block;
			position: absolute;
			left: 0;
			top: 6px;
			background: #f60;
			width: 30px;
			height: 26px;
			padding-top: 4px;
			border-radius: 30px;
			background: #fff;
			border: 1px solid #d8d0c5;
		}
		span {
			@extend %sprite;
			background-position: -424px -498px;
			display: block;
			clear: both;
			background-color: #fff799;
			border: 1px solid #e8df78;
			margin: 10px 0 0 -45px;
			padding: 10px 10px 10px 40px;
		}
	}
	.icon-steps {
		min-height: 26px;
		em {
			@extend %sprite;
			overflow: hidden;
			text-indent: 100%;
			white-space: nowrap;
			overflow: hidden;
			background-position: -427px -503px;
			&.tip { background-position: -340px -593px; }
		}
	}
	.ask-options & { width: 480px; }
}

.yellow {
	@extend %sprite;
	z-index: 10;
	position: absolute;
	left: 0;
	top: 15px;
	display: block;
	width: 5px;
	height: 17px;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	background-position: -188px -25px;
}

.check-list {
	display: block;
	float: left;
	width: 210px;
	padding-right: 20px;
	@media only screen and (max-width: $break-small) {
		width: 290px;
		padding-right: 0;
	}
	li {
		@extend %sprite;
		background-position: -490px -937px;
		padding: 2px 0 2px 20px;
	}
}

.entry ul,
.bull-list {
	margin-bottom: 10px;
	li {
		line-height: 18px;
		padding: 0 0 5px 10px;
		background: url(images/backgrounds/bull-blue.png) 0 6px no-repeat;
		@media only screen and (max-width: $break-small) {
			line-height: 16px;
		}
		a { text-decoration: none; }
		div {
			display: none;
			padding: 10px 0;
			border-bottom: 1px solid #ebe4db;
		}
	}
	.title {
		font-weight: 700;
		background-image: none;
		padding: 0px 0px 5px;
	}
	.optional {
		background: no-repeat;
		font-style: italic;
		font-weight: 700;
		padding: 0 0 5px;
	}
}

.bull-list {
	@media only screen and (max-width: $break-small) {
		margin: 10px 0 0;
	}
}

.why-list li {
	@extend %sprite;
	color: #4b5342;
	display: block;
	float: left;
	font-size: 13px;
	width: 198px;
	padding: 6px 0 11px 27px;
	background-position: 0 -1320px;
}

.bull-link {
	background: url(images/backgrounds/bull-blue.png) 0 6px no-repeat;
	padding: 0 0 5px 10px;
	line-height: 18px;
}

.img-right {
	float: right;
	margin: 5px 0 10px 20px;
}

.img-left {
	float: left;
	margin: 5px 20px 10px 0;
}

.thumb-content {
	width: 150px;
	float: left;
	margin: 0 20px 20px 0;
}

.img-right img,
.img-left img,
.thumb-content img {
	vertical-align: bottom;
}

.img-right figcaption,
.img-left figcaption,
.thumb-content figcaption {
	background: #eee8e0;
	padding: 5px;
	border-bottom: 1px solid #d8d0c5;
}

.needs {
	background: #eee8e0;
	border: 1px solid #d8d0c5;
	padding: 10px 10px 0 10px;
	display: block;
	margin: 20px 0;
	h3 {
		font-size: 16px;
		display: block;
		border-bottom: 1px solid #d8d0c5;
		padding: 0 0 5px 0;
		margin-bottom: 15px
	}
	ul { padding-bottom: 20px; }
	li {
		display: block;
		float: left;
		padding: 5px 5px 5px 0;
		border-right: 1px solid #c3bcb3;
		margin-left: 10px;
		a {
			@extend %sprite;
			padding: 0 35px 0 0;
			background-position: right -717px;
			color: #333;
			text-decoration: none;
			&:hover { text-decoration: underline; }
		}
	}
	.bull-list {
		display: block;
		float: left;
		width: 200px;
	}
	.title {
		display: block;
		clear: both;
		font-weight: bold;
		float: none;
		border: none;
		background: none;
	}
	.del-all {
		display: block;
		clear: both;
		margin: 0 0 10px 10px;
	}
}

.quote {
	border-bottom: 1px solid #d8d0c5;
	margin-bottom: 10px;
	padding-bottom: 10px;
	em {
		font-style: italic;
		color: #959595;
	}
}