.wrap-slides { display: none; }
		.wrap-slides { height: 150px; }
		.wrap-slides { width: 260px; }
		.wrap-slides p { padding-top: 0; }
		.wrap-slides p span { display: none; }
		#slide-state li a { margin: 9px 0 4px 7px; }
		#slide-state { padding-right: 2px; }

.wrap-slides {
	display: block;
	width: 450px;
	height: 140px;
	overflow: hidden;
	position: relative;
	section {
		display: block;
		float: left;
		height: 138px;
		width: 450px;
		overflow: hidden;
	}
	figure {
		@include box-shadow(0 1px 2px rgba(black, 0.2));
		background: $white;
		border-radius: 3px;
		width: 221px;
		height: 79px;
		float: left;
		margin: 0 20px 10px 1px;
		span {
			display: block;
			overflow: hidden;
			width: 213px;
			height: 71px;
			border-radius: 3px;
			margin: 4px;
			position: relative
		}
		img {
			width: 213px;
			height: auto;
		}
		p {
			color: #4b5342;
			padding: 5px 0 0;
			float: left;
			width: 215px;
			span { padding-top: 15px; }
			a {
				background: url(images/backgrounds/bull-blue.png) 0 6px no-repeat;
				padding: 0 0 0 10px;
				text-decoration: none;
				&:hover, &:focus {
					text-decoration: underline;
				}
			}
		}
	}
}

.video {
	a em {
		@extend %sprite;
		display: block;
		width: 48px;
		height: 48px;
		position: absolute;
		top: 10px;
		left: 84px;
		text-indent: 100%;
		white-space: nowrap;
		overflow: hidden;
		background-position: 100% -350px;
	}
	a:hover em { background-position: -384px -350px; }
}


#slide-state {
	@include box-shadow(0 1px 2px rgba(black, 0.1));
	position: absolute;
	right: 0;
	top: 9px;
	background: $white;
	padding: 0 2px 0 6px;
	border-radius: 30px;
	height: 18px;
	li	a {
		@extend %sprite;
		display: block;
		float: left;
		background-position: -130px -660px;
		width: 10px;
		height: 10px;
		text-indent: 100%;
		white-space: nowrap;
		overflow: hidden;
		margin: 4px 4px 4px 0;
	}
	li.current a { background-position: -120px -660px; }
}