/* The clearfix mixin is necessary for the floats */
@mixin clearfix {
    *zoom: 1;
    &:before, &:after { display: block; height: 0 !important; line-height: 0; overflow: hidden; visibility: hidden; content:"."; }
    &:after { clear: both; }
}

.clear {
	@include clearfix;
}

.wrapper {
	width: 980px;
	margin: 0 auto;
}

.wrap-content {
	@include clearfix;
	background: #f7f3ef;
	width: 940px;
	padding: 5px 20px 0 20px;
	display: block;
	margin: 0 auto;
	min-height: 100px;
	position: relative;
	@media only screen and (max-width: $break-small) {
		width: 320px;
		margin: 0 auto;
		padding: 0 0 70px;
		background: $white;
		.tax-in-the-garden &,
		.tax-on-the-house &,
		.tax-in-the-house &,
		.page-template-page-area-php &,
		.page-template-page-inthehouse-php &,
		.page-template-page-onthehouse-php &,
		.page-template-page-inthegarden-php &,
		.page-template-page-help-advice-php & { background: $white url('images/backgrounds/wood-top-mobile.png') 0 0 no-repeat; }
	}
	.home & {
		padding: 30px 30px 0;
		width: 920px;
		@media only screen and (max-width: $break-small) {
			width: 320px;
			padding: 0 0 50px;
		}
	}
}

%column {
	display: block;
	float: left;
}

.column-700 {
	@extend %column;
	@include clearfix;
	width: 700px;
	@media only screen and (max-width: $break-small) {
		display: none;
	}
}

.column-460 {
	@extend %column;
	padding: 15px 0 40px 0;
	margin: 0 20px 0 0;
	width: 460px;
	@media only screen and (max-width: 767px) {
		margin: 0 auto;
		padding: 0;
		width: 290px;
		float: none;
	}
}

.column-440 {
	@extend %column;
	width: 440px;
	margin-left: 20px;
	padding: 65px 0 40px 0;
	@media only screen and (max-width: 767px) {
		margin: 0 auto;
		padding: 0;
		width: 290px;
		float: none;
	}
}

.column-313 {
	@extend %column;
	width: 313px;
}

.column-288 {
	@extend %column;
	width: 288px;
}

.column-220 {
	@extend %column;
	width: 220px;
	figure {
		display: block;
		width: 220px;
		height: 100px;
		border-bottom: 5px solid #506602;
		margin-bottom: 20px;
		position: relative;
		span {
			display: block;
			overflow: hidden;
			width: 58px;
			height: 52px;
			position: absolute;
			right: 0;
			bottom: -24px;
		}
	}
}

.column-210 {
	@extend %column;
	width: 210px;
	margin-right: 20px;
	position: relative;
}

.two-blocks {
	@include clearfix;
	display: block;
	clear: both;
	border-top: 1px solid #ebe4db;
	padding: 25px 0 40px;
	@media only screen and (max-width: $break-small) {
		border: 0;
		padding: 0 35px;
	}
	.home & {
		border: 0;
		padding-top: 0;
		padding-bottom: 20px;
	}
}

.column-700-content {
	width: 700px;
	display: block;
	float: left;
	padding: 15px 0 50px;
	@media only screen and (max-width: $break-small) {
		float: none;
		padding: 0;
		width: auto;
		padding: 30px 15px 15px;
		background: $white url('images/backgrounds/wood-top-mobile.png') 0 0 no-repeat;
		h1 { margin-top: 15px; }
		.page-template-page-help-advice-php &,
		.page-template-page-area-php &,
		.page-template-page-inthehouse-php &,
		.page-template-page-onthehouse-php &,
		.page-template-page-inthegarden-php & {
			display: none;
		}
	}
}
.column-full-content {
	width: 100%;
	display: block;
	float: left;
	padding: 15px 0 50px;
	@media only screen and (max-width: $break-small) {
		.page-template-page-storelocator-php & {
			padding: 15px 15px 0;
			width: 290px;
		}
	}
}

.column-450-home {
	display: block;
	width: 450px;
	float: left;
	@media only screen and (max-width: $break-small) {
		width: 100%;
	}
}

.middle-column {
	margin: 0 20px 20px;
}

.middle-border {
	border: 1px solid #d7cfc4;
	border-width: 0 1px;
	width: 272px;
	padding: 0 20px;
	#content & {
		border-color: #645139;	
	}
}

.last-col {
	padding: 0 20px;
	width: 263px;
}

.left-20 {
	margin-left: 20px;
	@media only screen and (max-width: $break-small) {
		display: none;
	}
}

.img-460,
.img-440 {
	width: 460px;
	overflow: hidden;
	background: #eee8e0;
	height: 200px;
	margin-bottom: 20px;
	@media only screen and (max-width: $break-small) {
		width: 290px;
		height: auto;
		img {
			width: 290px;
			height: auto;
		}
	}
}

.img-440 {
	width: 440px;
}