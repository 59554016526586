/*!
Theme Name: Woodcare
Description: The WordPress theme for Woodcare websites
Author: Concept7 & Uprise
*/
//---- Core
@import "css/core/settings.scss";
@import "css/core/mixins.scss";
@import "css/core/base.scss";
@import "css/core/general.scss";
@import "css/core/grid.scss";

//---- Modules
@import "css/modules/topbar.scss";
@import "css/modules/header.scss";
@import "css/modules/navigation.scss";
@import "css/modules/navigation-left.scss";
@import "css/modules/navigation-service.scss";
@import "css/modules/breadcrumbs.scss";
@import "css/modules/fly-out.scss";
@import "css/modules/slider.scss";
@import "css/modules/productfinder.scss";
@import "css/modules/slider-howto.scss";
@import "css/modules/tabs.scss";
@import "css/modules/tables.scss";
@import "css/modules/forms.scss";
@import "css/modules/ratings.scss";
@import "css/modules/related.scss";
@import "css/modules/reviews.scss";
@import "css/modules/color-selector.scss";
@import "css/modules/tooltip.scss";
@import "css/modules/qtip.scss";
@import "css/modules/weather.scss";
@import "css/modules/storelocator.scss";
@import "css/modules/footer.scss";
@import "css/modules/product-compare.scss";
@import "css/modules/woodchips.scss";
@import "css/modules/popup.scss";
@import "css/modules/modal.scss";
@import "css/modules/cookie-consent.scss";

@import "css/modules/page-general.scss";
@import "css/modules/page-home.scss";
@import "css/modules/page-area.scss";
@import "css/modules/page-archive-product.scss";
@import "css/modules/page-detail.scss";
@import "css/modules/page-news.scss";
@import "css/modules/page-shopping-list.scss";
@import "css/modules/page-search.scss";

@import "css/modules/smallscreen.scss"; // Mobile CSS code only

@import "css/modules/OLD.scss";