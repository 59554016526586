* {
	margin: 0;
	padding: 0;
}

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video {
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	margin: 0;
	padding: 0;
}

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
	display: block;
}

nav li, #slide-state li, footer li, .breadcrumbs li, .options li, #tabs li, .rate-review li, .select-item li, .tab-nav li, .print-options li, .needs li {
	display: inline;
}

@font-face {
    font-family: 'swis721_ltcn_btlight';
    src: url('fonts/swiss_721_light_condensed.eot');
    src: url('fonts/swiss_721_light_condensed.eot?#iefix') format('embedded-opentype'),
         url('fonts/swiss_721_light_condensed.woff') format('woff'),
         url('fonts/swiss_721_light_condensed.ttf') format('truetype'),
         url('fonts/swiss_721_light_condensed.svg#swis721_ltcn_btlight') format('svg');
    font-weight: normal;
    font-style: normal;
}

html {
	-webkit-text-size-adjust: none; /* Prevent font scaling in landscape */
	height: 100%;
}

body {
	color: #363636;
	font: normal 14px $font-family;
	line-height: 1;
	@media only screen and (max-width: $break-small) {
		background: none;
		overflow-x: hidden;
		min-height: 100%;
	}
}

h1, h2, h3, h4 {
	font-family: $font-web;
	font-size: 32px;
	color: #203403;
	font-weight: 400;
	padding-bottom: 10px;
	.ru_ru &,
	.hu_hu &,
	.lv_lv &,
	.lt_lt &,
	.ee_ee &,
	.ua_ua &,
	.cs_cz &,
	.sk_sk &,
	.pl_pl & {
		font-family: $font-family;
	}
	.ru_ru &,
	.hu_hu &,
	.cs_cz &,
	.sk_sk &,
	.pl_pl & {
		font-size: 30px;
	}
}
h1 {
	@media only screen and (max-width: $break-small) {
		font-size: 28px;
	}
}
h2, h3 {
	font-size: 30px;
	.ru_ru &,
	.hu_hu &,
	.cs_cz &,
	.sk_sk &,
	.pl_pl & { font-size: 22px; }
}

p {
	padding-bottom: 17px;
	line-height: 18px;
	@media only screen and (max-width: $break-small) {
		line-height: 16px;
	}
}

a, a:visited {
	color: #006f9f;
	text-decoration: underline;
}
a:hover,
a:visited:hover { color: #1e3407; }

strong { font-weight: 700; }

ol,
ul { list-style: none; }
.single-woodipedia .column-700-content ul {
	list-style: disc;
	margin-left: 30px;
}
.single-woodipedia .column-700-content ol {
	list-style: decimal;
	margin-left: 30px;
}

blockquote,
q { quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0
}

address {
	line-height: 140%;
	padding-bottom: 15px
}