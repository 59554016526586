#topbar {
	background: $color-brand;
	background: rgba($color-brand, 0.9);
	height: 48px;
	@media only screen and (max-width: $break-small) {
		background: #1e3407;
		margin: 0 auto;
		padding-top: 13px;
		width: 274px;
	}
	.wrapper {
		position: relative;
		@media only screen and (max-width: $break-small) {
			margin: 0;
			position: relative;
			width: auto;
		}
		#logo {
			position: absolute;
			left: 19px;
			z-index: 10;
			@media only screen and (max-width: $break-small) {
				float: left;
				height: 70px;
				left: 94px;
				position: absolute;
				top: -13px;
				width: auto;
				z-index: 500;
			}
		}
		.menu-trigger {
			@extend %sprite;
			@media only screen and (max-width: $break-small) {
				@include border-radius(2px);
				background-color: #355c0b;
				background-position: 10px -680px;
				border-bottom: 1px solid #182a06;
				border-top: 1px solid #4a6d24;
				color: $white;
				cursor: pointer;
				float: left;
				font-weight: 700;
				height: 12px;
				line-height: 12px;
				padding: 10px 15px 10px 28px;
				text-decoration: none;
				text-shadow: 1px 1px #182a05;
				width: 30px;
			}
			&:hover, &:focus {
				background-color: darken(#355c0b, 1%);
			}
		}
		.mob-search {
			@extend %sprite;
			float: right;
			@media only screen and (max-width: $break-small) {
				margin-top: 2px;
				color: $white;
				display: block;
				height: 15px;
				width: 15px;
				background-position: -460px -954px;
				padding: 5px;
				text-indent: -1234em;
				&:hover, &:focus {
					opacity: 0.6;
				}
			}
		}
	}
}