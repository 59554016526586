.faq-weather {
	display: block;
	clear: both;
	border-top: 1px solid #ebe4db;
	margin: 0 0 0 240px;
	padding: 20px 0 40px 0;
	position: relative;
	min-height: 180px;
	@media only screen and (max-width: $break-small) {
		margin: 15px;
		height: auto;
	}
	.weather {
		background-color: #eee8e0;
		background-position: 90px 65px;
		background-repeat: no-repeat;
		display: block;
		width: 220px;
		height: 145px;
		padding: 20px 0 0 20px;
		position: absolute;
		right: 0;
		top: 0;
		em {
			font-family: $font-web;
			font-size: 32px;
			display: block;
			height: 60px;
			padding-top: 30px;
			.hu_hu &,
			.ru_ru &,
			.cs_cz &,
			.sk_sk &,
			.lv_lv &,
			.lt_lt &,
			.ee_ee &,
			.ua_ua & {
				font-family: $font-family;
				font-size: 30px;
			}
		}
	}
	.sunny { background-image: url('images/globals/weather/icon-sun.png'); }
	.cloudy { background-image: url('images/globals/weather/icon-clouds.png'); }
	.rainy { background-image: url('images/globals/weather/icon-rain.png'); }
}