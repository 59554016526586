.slider-container {
	height: 382px;
	position: relative;
	width: 980px;
	display: block;
	margin: 0 auto;
	@media only screen and (max-width: $break-small) {
		background: $white;
		height: 420px;
		width: 320px;
	}
	.slider {
		overflow: hidden;
		position: relative;
		height: 382px;
		width: 980px;
		@media only screen and (max-width: $break-small) {
			display: none;
		}
		&:before {
			@include transform(rotate(-1.4deg));
			background: #F7F3EF;
			bottom: -18px;
			content: "";
			display: block;
			height: 30px;
			position: absolute;
			width: 110%;
			z-index: 5;
			@media only screen and (max-width: $break-small) {
				display: none;
			}
		}
	}
	.slides {
		position: absolute;
		left: 0;
		top: 0;
		width: 9000px;
	}
	.slide {
		float: left;
		position: relative;
	}
	span {
		position: absolute;
		left: 0;
		top: 130px;
		@media only screen and (max-width: $break-small) {
			display: none;
		}
		h3 {
			background: $color-brand;
			background: rgba($color-brand, 0.85);
			color: $color-contrast;
			display: block;
			padding: 20px 30px;
			font-size: 32px;
			max-width: 550px;
			line-height: 36px;
		}
		a {
			@extend %sprite;
			background-position: 100% -1031px;
			color: #333;
			display: inline-block;
			height: 16px;
			padding: 13px 30px 21px;
			text-decoration: none;
			max-width: 420px;
			&:hover, &:focus {
				text-decoration: underline;
			}
		}
	}
	.bullets {
		position: absolute;
		bottom: 25px;
		left: 30px;
		@media only screen and (max-width: $break-small) {
			display: none;
		}
		li {
			@include border-radius(50%);
			background: rgba(white, 0.5);
			display: block;
			float: left;
			height: 6px;
			margin-right: 6px;
			text-indent: -999em;
			width: 6px;
		}
		.is-selected {
			@include box-shadow(0 0 4px rgba(255,255,255, 0.9));
			background: $white;
		}
	}
	.productfinder-container {
		position: absolute;
		right: 30px;
		top: 30px;
		z-index: 10;
		@media only screen and (max-width: $break-small) {
			right: auto;
			top: auto;
		}
	}
}