.results {
	position: relative;
	@media only screen and (max-width: $break-small) {
		.center { text-align: left; }
		h1 { font-size: 16px; }
	}
	article {
		border-bottom: 1px solid #d8d0c5;
		margin-bottom: 15px;
		padding-bottom: 10px;
		@media only screen and (max-width: $break-small) {
			@include border-radius(4px);
			padding: 10px 27px 10px 10px;
			border: 1px solid #BBB;
			background: url('images/backgrounds/bg-searchresults.png') 270px 50% no-repeat;
		}
		li {
			padding-bottom: 5px;
			color: #959595;
			a {
				font-weight: 700;
				@media only screen and (max-width: $break-small) {
					font-size: 14px;
				}
			}
		}
		p { padding: 0; }
	}
}