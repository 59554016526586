.print-options {
	background: #eee8e0;
	border: 1px solid #c3bcb3;
	margin-bottom: 20px;
	li a {
		@extend %sprite;
		display: block;
		float: left;
		padding: 15px 15px 15px 35px;
		border-right: 1px solid #c3bcb3;
		background-position: -451px -885px;
	}
	li.email a {
		background-position: -450px -915px;
	}
}

.product {
	border-bottom: 1px solid #ebe4db;
	margin-bottom: 20px;
	p {
		clear: both;
	}
	figure {
		float: left;
		display: block;
		width: 120px;
		height: 120px;
		overflow: hidden;
		margin-top: 5px;
	}
}

.product-info {
	width: 500px;
	margin-right: 20px;
	float: left;
	margin-left: 20px;
}

.amount {
	background: #eee8e0;
	border: 1px solid #c3bcb3;
	padding: 10px;
	float: right;
	input,
	.plus,
	.minus,
	.delete {
		display: block;
		float: left;
	}
	input {
		border: 1px solid #c3bcb3;
		height: 28px;
		width: 50px;
		margin: 0 10px;
		padding: 0 10px;
		text-align: center;
	}
	.plus,
	.minus {
		@extend %sprite;
		width: 30px;
		height: 30px;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
		background-position: 0 -350px;
		&:hover, &:focus {
			background-position: -30px -350px;
		}
	}
	.minus {
		background-position: -60px -350px;
		&:hover, &:focus {
			background-position: -90px -350px;
		}
	}
	.delete,
	.delete:visited {
		color: #f00;
		margin: 8px 0 0 20px;
		&:hover, &:focus {
			color: darken(#f00, 10%);
		}
	}
}