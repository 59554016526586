#popup_container {
	@include border-radius(5px);
	font-family: $font-family;
	font-size: 12px;
	color: $black;
	display: none;
	padding: 15px;
	z-index: 100000;
	position: absolute;
	width: 500px;
	background: #FF9;
	border: 1px solid #E1E162;
	opacity: 1.0;
	p { padding: 0; }
	.error {
		border: 0;
		padding: 0;
	}
}
#popup_title {
	font-size: 17px;
	font-weight: 700;
	text-align: center;
	line-height: 1.75em;
	color: #666;
	cursor: default;
	padding: 0em;
	margin: 0em;
}
#popup_panel {
	text-align: center;
	margin: 1em 0em 0em 1em;
}
#popup_prompt {
	margin: .5em 0em;
}
a.close-note, a.close-note:visited,
a.close-popup, a.close-popup:visited {
	height: 11px;
	overflow: hidden;
	float: right;
}

#itro_popup {
	#popup_content {
		img {
			max-width: 100%;
			height: auto;
		}
	}
}

@media screen and (max-width: 780px) {
	#itro_popup {
		height: auto !important;
	}
}