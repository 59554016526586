.rating {
	display: block;
	clear: both;
	float: left;
	margin-bottom: 20px;
	width: 460px;
	li {
		display: inline-block;
		float: left;
		padding-top: 2px;
	}
	.title-rating {
		padding-bottom: 10px;
	}
	.stars {
		@extend %sprite;
		background-position: 0 -980px;
		width: 113px;
		height: 17px;
		margin-right: 20px;
		padding-top: 0;
		display: block;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
	}
	.star-5 { background-position: 0 -720px; }
	.star-4_5 { background-position: 0 -740px; }
	.star-4 { background-position: 0 -760px; }
	.star-3_5 { background-position: 0 -780px; }
	.star-3 { background-position: 0 -800px; }
	.star-2_5 { background-position: 0 -820px; }
	.star-2 { background-position: 0 -840px; }
	.star-1_5 { background-position: 0 -860px; }
	.star-1 { background-position: 0 -880px; }
	.star-0_5 { background-position: 0 -900px; }
	.star-0 { background-position: 0 -920px; }
}