// =========== Modal

.is-hidden {
	display: none;
}

div.modal {
	left: 0;
	overflow: none;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1000;
	&:after {
		background: rgba($black, 0.7);
		content: "";
		height: 100%;
		opacity: 0.9;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: -1;
	}
}

div.modal-content {
	background: $white;
	box-shadow: 0px 0px 20px rgba($black, 0.5);
	margin: 8em auto;
	max-width: 735px;
	position: relative;
	top: 0;
	.modal-content-top {
		@include clearfix;
		background: $color-brand;
		padding: 1.25em;
		h2 {
			color: $color-contrast;
			font-size: 1.75em;
			font-weight: normal;
			padding-bottom: 0;
			float: left;
		}
		a {
			float: right;
			text-indent: 1234em;
			width: 13px;
			height: 17px;
			display: block;
			background: url(images/globals/sprite.png) right -995px no-repeat;
			&:hover,
			&:focus {
				opacity: 0.8;
			}
		}
	}
	.modal-content-intro {
		@include clearfix;
		border-bottom: 1px solid #ebe4db;
		padding: 1.25em;
		a { float: right; margin-left: 20px; }
		p { padding-bottom: 0; }
	}
	.modal-content-wood {
		background: #f7f3ef;
		padding: 1.25em 0 0 1.25em;
		max-height: 300px;
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
		@media only screen and (max-width: $break-small) {
			max-height: 175px;
		}
		&:before {
			content: "";
			width: 100%;
			height: 10px;
			box-shadow: inset 0 -5px 5px rgba($black, 0.25);
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 100;
		}
	}
}

.modal-content-wood ul {
	@include clearfix;
	li { 
		float: left;
		margin-right: 18px;
		margin-bottom: 20px;
		width: 100px;
		em {
			font-weight: bold;
			font-style: normal;
			color: #333;
			display: block;
			padding: 0 0 8px 0;
		}
		figure {
			width: 100px;
			height: 70px;
			background: url(images/globals/sprite.png) -100px -1201px no-repeat;
			position: relative;
			span {
				background: url(images/globals/sprite.png) 0 -1201px no-repeat;
				height: 10px;
				position: absolute;
				top: 0;
				left: 0;
				font-size: 0em;
				text-indent: -9999em;
				width: 100px;
				z-index: 2;
			}
			em.code {
				position: absolute;
				top: 27px;
				z-index: 20;
				color: #FFF;
				text-align: center;
				left: 0;
				width: 100%;
				font-weight: normal;
				text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
			}
			em.layers-1,
			em.layers-2 {
				width: 100px;
				height: 35px;
				text-indent: -9999em;
				font-size: 0em;
				z-index: 1;
				position: absolute;
				left: 0;
				top: 0;
				padding-bottom: 0;
				@include opacity(0.4)
			}
			em.layers-2 {
				top: 35px;
				@include opacity(0.6)
			}
		}
	}
	li.layers-intro {
		figure:before {
			border-bottom: 1px dotted rgba($black, 0.25);
			position: absolute;
			top: 35px;
			height: 1px;
			width: 100%;
			left: 0;
			z-index: 2;
			content: "";
		}
		em.layers-1,
		em.layers-2 {
			background: $white;
			border-radius: 6px;
			width: 1.5em;
			height: 1em !important;
			font-size: 0.8em;
			font-weight: bold;
			margin-top: 0.9em;
			padding: 0.3em;
			text-indent: inherit;
			text-align: center;
			right: 0.5em;
			left: auto;
		}
		em.layers-1:before,
		em.layers-2:before,
		em.layers-2:after {
			width: 15px;
			height: 15px;
			content: "";
			display: block;
			background: url(images/globals/sprite.png) right -1013px no-repeat;
			position: absolute;
			left: -23px;
			top: 2px;
		}
		em.layers-2:after {
			left: -43px;
		}
	}
}